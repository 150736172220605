<template>
  <div :class="{ disabled: disabled }">
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <a-affix
          :offsetTop="50"
          :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right' }"
        >
          <a-button><router-link :to="{ name: 'CheckIn' }">取消</router-link></a-button>
          <a-divider type="vertical" />
          <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
        </a-affix>
      </div>
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="6">
            <a-form-model-item label="业务类型">
              <a-select
                showSearch
                ref="business_type"
                :filter-option="filterOption"
                allowClear
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="form.business_type"
              >
                <a-select-option v-for="op in bizTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="工作编号">
              <a-input v-model="form.serial_num" disabled placeholder="系统自动生成" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="计划号">
              <a-input v-model="form.plan_id" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="业务来源">
              <a-select
                showSearch
                ref="source"
                :filter-option="filterOption"
                allowClear
                placeholder="请选择业务来源"
                style="width: 100%"
                v-model="form.source"
                @change="sourceChange"
              >
                <a-select-option v-for="op in sourceOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="报关单号">
              <a-input v-model="form.report_num" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="报关单位">
              <a-select
                showSearch
                label-in-value
                :filter-option="false"
                allowClear
                placeholder="请选择报关单位"
                style="width: 100%"
                v-model="customerInfo"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customsCompanyOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="经营单位">
              <a-input v-model="form.operator_name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="运输单位">
              <a-input v-model="form.trans_name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="件数">
              <a-input-number v-model="form.total_num" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="毛重">
              <a-input v-model="form.total_gross_weight" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="净重">
              <a-input v-model="form.total_net_weight" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="品名">
              <a-input v-model="form.total_name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="布控部门">
              <a-input v-model="form.control_department" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="监管方式">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择监管方式"
                style="width: 100%"
                v-model="form.supervise_type"
              >
                <a-select-option v-for="op in superviseTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="布控指令">
              <a-input v-model="form.control_command" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="入库日期">
              <a-date-picker v-model="form.purchase_date" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="企业类型">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择企业类型"
                style="width: 100%"
                v-model="form.company_type"
              >
                <a-select-option v-for="op in companyTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="工号">
              <a-input v-model="form.worker_num" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否押运">
              <a-switch default-checked checked-children="是" un-checked-children="否" v-model="form.escort" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="出库日期">
              <a-date-picker v-model="form.release_date" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="收货单位">
              <a-input v-model="form.receiver" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="用车数量">
              <a-input-number @change="vehicleChange" v-model="form.vehicle_num" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="金额">
              <a-input v-model="form.charge" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="币种">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择币种"
                style="width: 100%"
                v-model="form.currency_type"
              >
                <a-select-option v-for="op in currencyTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="货物状态">
              <a-select
                showSearch
                ref="commodity_type"
                :filter-option="filterOption"
                allowClear
                placeholder="请选择货物状态"
                style="width: 100%"
                v-model="form.commodity_type"
              >
                <a-select-option v-for="op in commdityTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="联系人">
              <a-input v-model="form.contact_name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="联系电话">
              <a-input v-model="form.contact_phone" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="暂存原因">
              <a-textarea v-model="form.stock_reason"></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="布控/卡口官员">
              <a-input v-model="form.control_officer" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="集装箱号">
              <a-input v-model="form.box_serial_num" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="箱型">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择箱型"
                style="width: 100%"
                v-model="form.box_type"
              >
                <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="箱量">
              <a-input-number v-model="form.box_num" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="查验情况简述">
              <a-textarea v-model="form.sketch"></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="货物处置">
              <a-textarea v-model="form.commodity_deal"></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="备注">
              <a-textarea v-model="form.remark"></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="查验方式">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                mode="multiple"
                placeholder="请选择查验方式"
                style="width: 100%"
                v-model="form.inspect_type"
              >
                <a-select-option v-for="op in inspectTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否下发仓储">
              <a-switch default-checked checked-children="是" un-checked-children="否" v-model="form.to_store" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="录单时间">
              <a-date-picker v-model="form.receive_time" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider orientation="left">违规申报内容</a-divider>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="品名">
              <a-input v-model="form.violation_name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="违规性质">
              <a-input v-model="form.violation" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="HS">
              <a-input v-model="form.hs" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="金额">
              <a-input v-model="form.violation_charge" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="原产地">
              <a-input v-model="form.origin_area" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="其他">
              <a-input v-model="form.other_msg" />
            </a-form-model-item>
          </a-col>
          <a-divider orientation="left"></a-divider>
          <a-col :span="6">
            <a-form-model-item label="仓储进库日期">
              <a-date-picker v-model="form.in_date" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="仓储出库日期">
              <a-date-picker v-model="form.out_date" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="入库总立方">
              <a-input-number v-model="form.in_cube" style="width:100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="入库操作员">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择操作员"
                style="width: 100%"
                ref="operator_name"
                v-model="form.in_operator"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="出库总立方">
              <a-input-number v-model="form.out_cube" style="width:100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="出库操作员">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择操作员"
                style="width: 100%"
                ref="operator_name"
                v-model="form.out_operator"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="总天数">
              <a-input-number v-model="form.wms_days" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="18">
            <a-form-model-item label="备注">
              <a-textarea v-model="form.wms_remark"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <a-card class="card" title="其它信息" :bordered="false">
      <a-row :gutter="20">
        <a-col :span="24">
          <a-tabs :activeKey="activeKey" @change="
            key => {
              activeKey = key
            }
          ">
            <a-tab-pane tab="海关查验费用" key="1">
              <edit-table ref="editTable" :columns="feeColumns" :sourceData="feeData" @getTableDate="getFeeData" @getChangeData="getTableChangeData"></edit-table>
            </a-tab-pane>
            <a-tab-pane tab="操作信息" key="2">
              <edit-table
                scroll
                :disabled="true"
                :columns="operateColumns"
                :sourceData="operateData"
                @getTableDate="getOperateData"
              ></edit-table>
            </a-tab-pane>
            <a-tab-pane tab="仓储查验费用" key="3" forceRender>
              <a-row :gutter="20">
                <a-col>
                  <span class="table-title">仓储查验费用</span>
                  <edit-table
                    scroll
                    :disabled="true"
                    :columns="chargeFeeColumns"
                    :sourceData="chargeFeeData"
                    @getTableDate="getChargeFeeData"
                  ></edit-table>
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { getCommonOptions, getCustomer } from '@/api/common'
import { getCmsInfoDetail, getCmsMetaOption, saveCmsInfo, getCmsInit } from '@/api/cms'
import { EditTable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick'
import debounce from 'lodash/debounce';
import { getWmsDataOption } from '@/api/wms'
import { getCmsFeeTypes } from '@/api/wms/sale'
export default {
  components: {
    EditTable, preventReClick
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      operateData: [],
      chargeFeeData: [],
      activeKey: '1',
      customerInfo: undefined,
      operatorOps: [],
      fetching: false,
      form: {},
      sourceForm: {
        id: null,
        business_type: 2,
        serial_num: '',
        plan_id: '',
        source: 1,
        report_num: '',
        customs_company: null,
        operator_name: '',
        trans_name: '',
        total_num: null,
        total_gross_weight: '',
        total_net_weight: '',
        total_name: '',
        control_department: '二科',
        supervise_type: null,
        control_command: '',
        purchase_date: null,
        company_type: null,
        worker_num: '',
        escort: false,
        release_date: null,
        receiver: '',
        vehicle_num: null,
        charge: '',
        currency_type: null,
        commodity_type: 4,
        contact_name: '',
        contact_phone: '',
        stock_reason: '',
        control_officer: '',
        box_serial_num: '',
        box_type: 21,
        box_num: null,
        sketch: '',
        commodity_deal: '',
        remark: '',
        inspect_type: [4],
        to_store: false,
        receive_time: null,
        violation_name: '',
        violation: '',
        hs: '',
        violation_charge: '',
        origin_area: '',
        other_msg: '',
        // 仓储查验
        in_date: null,
        out_date: null,
        in_cube: null,
        in_operator: null,
        out_cube: null,
        out_operator: null,
        wms_days: null,
        wms_remark: ''
      },
      isEdit: false,
      disabled: false,
      bizTypeOps: [],
      sourceOps: [],
      customsCompanyOps: [],
      customCompanyMap: {}, // 报关单位对应联系信息map
      superviseTypeOps: [],
      companyTypeOps: [],
      currencyTypeOps: [],
      commdityTypeOps: [],
      boxOps: [],
      inspectTypeOps: [],
      feeData: [],
      rules: {
        // business_department: [{ required: true, message: '请选择业务部门', trigger: 'blur' }]
      },
      feeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          extra: {
            defaultValue: 173,
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 4, type: 1 }],
            options: []
          }
        },
        {
          title: '结算方式',
          dataIndex: 'charge_type',
          extra: {
            defaultValue: 1,
            type: 'select',
            options: [
              {
              name: '现金', value: 1
              },
              {
                name: '月结', value: 2
              }
            ]
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 1,
            hidden: true
          }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          extra: {
            defaultValue: 300,
            type: 'input'
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            type: 'text'
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          align: 'center',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1
          }
        }
      ],
      operateColumns: [
        {
          title: '操作人',
          dataIndex: 'operator_id',
          width: 100,
          extra: {
            type: 'select',
            func: getWmsDataOption,
            params: ['operator'],
            options: [],
            disabled: (record) => {
              return true
            }
          }
        },
        {
          title: '操作内容',
          dataIndex: 'content',
          width: 200,
          extra: {
            type: 'autocomplete',
            func: getWmsDataOption,
            params: ['cms_operation_type'],
            options: [],
            disabled: (record) => {
              return true
            }
          }
        },
        {
          title: '箱型',
          dataIndex: 'box_id',
          width: 100,
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['box_info'],
            options: [],
            disabled: (record) => {
              return true
            }
          }
        },
        {
          title: '箱量',
          dataIndex: 'box_num',
          width: 100,
          extra: {
            disabled: (record) => {
              return true
            }
          }
        }
      ],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          extra: {
            type: 'select',
            func: getCmsFeeTypes,
            params: [],
            options: [],
            disabled: (record) => {
              return true
            }
          }
        },
        {
          title: '结算方式',
          dataIndex: 'charge_type',
          extra: {
            defaultValue: 1,
            type: 'select',
            disabled: (record) => {
              return true
            },
            options: [
              {
                name: '现金', value: 1
              },
              {
                name: '月结', value: 2
              }
            ]
          }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: (record) => {
              return true
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            type: 'text'
          }
        },
        // {
        //   title: '交易号',
        //   dataIndex: 'trx_num',
        //   extra: {
        //     type: 'text',
        //     disabled: (record) => {
        //       return true
        //     }
        //   }
        // },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: text => {
            return this.GLOBAL.feeStatusMaps[text] ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        }
      ]
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'CheckInCUR') {
        // 编辑页路由监控
        this.handleFormReset()
        if (newRoute.params.id === 'create') {
          // 新增
          this.isEdit = false
          this.$route.meta.title = '新增业务登记'
          this.getDefaultDate()
        } else {
          // this.disabled = newRoute.query.disabled
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      // this.disabled = this.$route.query.disabled === true
      this.getEditData(this.$route.params.id)
    } else {
      this.getDefaultDate()
    }
  },
  created() {
    this.handleFormReset()
    this.getFormBasicData()
    getWmsDataOption('operator').then(v => {
      this.operatorOps = v
    })
  },
  methods: {
    getCommonOptions,
    moment,
    getDefaultDate() {
      getCmsInit().then((v) => {
        this.form.purchase_date = v.checkin_default_date
        this.form.receive_time = v.checkin_default_date
      })
    },
    getOperateData(data) {
      this.operateData = data
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    handleFormReset() {
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      this.customerInfo = undefined
      this.feeData = []
      // this.$nextTick((_) => {
      //   this.$refs.form.clearValidate()
      // })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getEditData(id) {
      this.isEdit = true
      this.$route.meta.title = '编辑业务登记'
      getCmsInfoDetail('check_in', { id: id }).then(v => {
        const record = v.check_in
        record['purchase_date'] = record['purchase_date'] ? moment(record['purchase_date'], 'YYYY/MM/DD') : null
        record['release_date'] = record['release_date'] ? moment(record['release_date'], 'YYYY/MM/DD') : null
        record['receive_time'] = record['receive_time'] ? moment(record['receive_time'], 'YYYY/MM/DD') : null
        record['in_date'] = record['in_date'] ? moment(record['in_date'], 'YYYY/MM/DD') : null
        record['out_date'] = record['out_date'] ? moment(record['out_date'], 'YYYY/MM/DD') : null
        this.form = {
          ...record,
          escort: !!record.escort,
          to_store: !!record.to_store
        }
        this.operateData = v.operation_list
        this.chargeFeeData = v.charge_fee_list
        this.customerInfo = {
          key: v.check_in.customs_company,
          label: v.check_in.customs_name
        }
        v.fee_list.forEach(item => {
          if (item.fee_status > 2 && item.fee_status !== 4) {
            item.disabled = true
          }
        })
        this.feeData = v.fee_list
      })
    },
    getFormBasicData() {
      getCmsMetaOption('business_type').then(v => {
        this.bizTypeOps = v
      })
      getCmsMetaOption('source').then(v => {
        this.sourceOps = v
      })
      getCmsMetaOption('supervise_type').then(v => {
        this.superviseTypeOps = v
      })
      getCmsMetaOption('company_type').then(v => {
        this.companyTypeOps = v
      })
      getCommonOptions('currency_type').then(v => {
        this.currencyTypeOps = v
      })
      getCmsMetaOption('commodity_type').then(v => {
        this.commdityTypeOps = v
      })
      getCommonOptions('box_info').then(v => {
        this.boxOps = v
      })
      getCmsMetaOption('inspect_type').then(v => {
        this.inspectTypeOps = v
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customsCompanyOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customsCompanyOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['customs_company'] = value.key
      this.form['customs_name'] = value.label
      this.customsCompanyOps = []
      this.fetching = false
    },
    getFeeData(data) {
      this.feeData = data
    },
    getTableChangeData(data) {
      if (data.column === 'fee_name_type') {
        if (data.value === 173) {
          if (this.form.source === 5) {
            this.$refs.editTable.data[data.index].money = this.form.vehicle_num > 1 ? (this.form.vehicle_num - 1) * 100 + 300 : 300
          } else {
            this.$refs.editTable.data[data.index].money = 0
          }
        } else {
          this.$refs.editTable.data[data.index].money = 0
        }
      }
    },
    // 医疗器械价格更改
    sourceChange(data) {
      if (data === 5) {
        this.$refs.editTable.data.forEach(item => {
          if (item.fee_name_type === 173) {
            item.money = this.form.vehicle_num > 1 ? (this.form.vehicle_num - 1) * 100 + 300 : 300
          }
        })
      } else {
        this.$refs.editTable.data.forEach(item => {
          if (item.fee_name_type === 173) {
            item.money = 0
          }
        })
      }
    },
    vehicleChange(data) {
      if (this.form.source === 5) {
        this.$refs.editTable.data.forEach(item => {
          if (item.fee_name_type === 173) {
            item.money = data > 1 ? (data - 1) * 100 + 300 : 300
          }
        })
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            check_in: {
              ...this.form,
              source_name: this.form.source ? this.$refs['source'].$el.innerText : '',
              commodity_type_name: this.form.commodity_type ? this.$refs['commodity_type'].$el.innerText : '',
              business_name: this.form.business_type ? this.$refs['business_type'].$el.innerText : '',
              purchase_date: this.form.purchase_date ? moment(this.form.purchase_date).valueOf() : null,
              release_date: this.form.release_date ? moment(this.form.release_date).valueOf() : null,
              receive_time: this.form.receive_time ? moment(this.form.receive_time).valueOf() : null,
              in_date: this.form.in_date ? moment(this.form.in_date).valueOf() : null,
              out_date: this.form.out_date ? moment(this.form.out_date).valueOf() : null
            },
            fee_list: this.feeData
          }
          saveCmsInfo('check_in', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            })
            this.$router.push({
              name: 'CheckIn',
              query: {
                isEdit: this.isEdit
              }
            })
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
